<template>
  <v-navigation-drawer app permanent dense>
    <template v-slot:prepend>
      <v-list-item dense class="px-2">
        <v-list-item-avatar>
          <v-avatar>
            <v-icon x-large>mdi-account-circle</v-icon>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>{{ getUser() }}</v-list-item-content>
      </v-list-item>
      <v-divider />
    </template>

    <v-list dense>
      <template v-for="(menu, index) in view.lists.menus">
        <!-- Menu sem submenus -->
        <v-list-item
          v-if="menu.submenus === undefined"
          :key="menu.title"
          @click="onClick(menu)"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ menu.title }}
          </v-list-item-content>
        </v-list-item>
        <!-- Menu com submenus -->
        <v-list-group
          v-else
          dense
          :key="`group_${index}`"
          v-model="menu.active"
          :prepend-icon="menu.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ menu.title }}</v-list-item-content>
          </template>
          <v-list-item
            dense
            v-for="subItem in menu.submenus"
            :key="subItem.title"
            sub-group
            @click="onClick(subItem)"
          >
            <v-list-item-content>{{ subItem.title }}</v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>

    <template v-slot:append>
      <v-divider />

      <v-list-item dense @click="onLogout" link>
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-content>Sair</v-list-item-content>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavigationDrawer',

  props: {},

  data: () => ({
    mini: true,
    view: {
      lists: {
        menus: []
      }
    }
  }),

  computed: {
    drawer: {
      get() {
        return true
        // return this.$store.state.drawer
      },
      set(val) {
        // this.$store.commit('SET_DRAWER', val)
      }
    }
  },

  mounted() {
    this.mounteMenus()
  },

  methods: {
    mounteMenus() {
      this.view.lists.menus = [
        {
          title: 'Dashboard',
          icon: 'mdi-view-dashboard',
          href: '/dash'
        },
        {
          title: 'Protocolos',
          icon: 'mdi-book-open-outline',
          href: '/protocols'
        },
        {
          title: 'Configurações',
          icon: 'mdi-cogs',
          href: '/settings'
        }
      ]
    },
    onClick(menu) {
      if (menu.href !== undefined && !this.$route.path.includes(menu.href)) {
        let href = menu.href
        if (href[0] != '/') {
          href = `/${menu.href}`
        }

        this.$router.push(href)
      }
    },

    onLogout() {
      localStorage.clear()
      this.$router.replace({ path: '/auth' })
    },

    getUser() {
      return JSON.parse(localStorage.getItem('user')).name
    }
  }
}
</script>

<style scoped>
.filter_menu {
  margin-top: 5px;
}

.image {
  height: 35px;
  width: 35px;
}
</style>
