import { render, staticRenderFns } from "./NavigationDrawer.vue?vue&type=template&id=0779ec50&scoped=true&"
import script from "./NavigationDrawer.vue?vue&type=script&lang=js&"
export * from "./NavigationDrawer.vue?vue&type=script&lang=js&"
import style0 from "./NavigationDrawer.vue?vue&type=style&index=0&id=0779ec50&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0779ec50",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VAvatar,VDivider,VIcon,VList,VListGroup,VListItem,VListItemAvatar,VListItemContent,VListItemIcon,VNavigationDrawer})
